import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./registerServiceWorker";
import ArgonDashboard from "./plugins/argon-dashboard";
import { auth } from "./config/firebase";
import store from "./store";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import VeeValidate from 'vee-validate';
import DateUtil from "./utils/dateUtil";
import VueIziToast from "vue-izitoast";
import ToggleButton from 'vue-js-toggle-button'

import "bootstrap-vue/dist/bootstrap-vue.css";
import "izitoast/dist/css/iziToast.min.css";

Vue.config.productionTip = false;

Vue.use(ArgonDashboard);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VeeValidate, {
  fieldsBagName: 'veeFields'
});

Vue.use(VueIziToast, { position: "topRight" });
Vue.use(ToggleButton)

export const bus = new Vue();

Vue.filter("dateAndTimeFormatter", (str) => {
  if (!str) return "";
  return DateUtil.getFormattedDateWithTime(str);
});

let app = "";
auth.onAuthStateChanged((user) => {
  if (user) {
    auth.currentUser
      .updateProfile({
        displayName: Object.assign(JSON.parse(localStorage.getItem('userCredentials'))).email,
      })
      .then(() => store.commit("user/SET_USER_DETAILS", user));
  }

  if (!app) {
    app = new Vue({
      store,
      router,
      render: (h) => h(App),
    }).$mount("#app");
  }
});
