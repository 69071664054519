export default {
  state: {
    currSalesContact: {},
  },
  getters: {
    salesContacts: (state) => state.salesContacts,
    currSalesContact: (state) => state.currSalesContact,
  },
  mutations: {
    SET_CURR_SALESCONTACT(state, salesContact) {
      state.currSalesContact = salesContact;
    },
    SET_ALL_SALESCONTACTS(state, salesContacts) {
      state.salesContacts = salesContacts;
    },
  },
};
