<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar
      logo="img/brand/brand.png"
      :background-color="sidebarBackground"
      class="ehi"
      short-title="EHI"
      title="EHI"
    >
      <template slot="links">
        <sidebar-item
          :link="{
            name: 'Users',
            icon: 'fa fa-user text-primary',
            path: '/users',
          }"
          short-title="users"
          title="Users"
          v-if="isUserSectionVisible"
        />
        <sidebar-item
          :link="{
            name: 'Quote Requests',
            icon: 'fa fa-calculator text-primary',
            path: '/quote-requests',
          }"
          short-title="quote requests"
          title="Quote Requests"
        />
        <sidebar-item
          :link="{
            name: 'Products',
            icon: 'fa fa-list text-primary',
            path: '/products',
          }"
          short-title="products"
          title="Products"
        />

        <sidebar-item
          :link="{
            name: 'Projects',
            icon: 'fa fa-handshake text-primary',
            path: '/projects',
          }"
          short-title=" projects"
          title="Projects"
        />
        <sidebar-item
          :link="{
            name: 'Clients',
            icon: 'fa fa-users text-primary',
            path: '/clients',
          }"
          short-title="clients"
          title="Clients"
        />
        <sidebar-item
          :link="{
            name: 'Sales Contacts',
            icon: 'fa fa-phone text-primary',
            path: '/sales-contacts',
          }"
          short-title="sales-contacts"
          title="Sales Contacts"
        />
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardNavbar from './DashboardNavbar.vue'
import { FadeTransition } from 'vue2-transitions'
import { auth } from '../config/firebase'

export default {
  components: {
    DashboardNavbar,
    // ContentFooter,
    FadeTransition,
  },
  data() {
    return {
      sidebarBackground: 'vue', //vue|blue|orange|green|red|primary
      userId: null,
      userCredential: null,
      currentUser: null,
    }
  },
  computed: {
    isUserSectionVisible() {
      return this.userCredential === 'support@tawitech.ph'
    },
  },
  created() {
    this.setUserId()
    auth.onAuthStateChanged((user) => {
      if (user) {
        localStorage.setItem('userId', user.uid)
        this.userId = user.uid
      } else {
        localStorage.removeItem('userId')
        this.userId = null
      }
    })
  },
  mounted() {
    const userId = localStorage.getItem('userId')
    const userCredential = localStorage.getItem('userCredentials')
    const authen = this.$store.getters['auth/loggedUser']
    if (userId) {
      this.userId = userId
    }
    if (userCredential) {
      this.userCredential = JSON.parse(userCredential).email
    }
    if (authen) {
      this.currentUser = authen
    }
  },
  methods: {
    setUserId() {
      const user = auth.currentUser
      if (user) {
        this.userId = user.uid
      } else {
        this.userId = null
      }
    },
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false)
      }
    },
  },
}
</script>
<style lang="scss">
.navbar-vertical.navbar-expand-md .navbar-brand-img {
  max-height: 4.5rem !important;
}
</style>
