<template>
  <div id="app">
    <notifications></notifications>
    <router-view />
  </div>
</template>
<script>
import { Validator } from "vee-validate";
export default {
  name: "app",
  created() {
    Validator.extend("selectRequired", {
      getMessage: (field) => "The " + field + " field is required",
      validate: (value) => value.name !== " - Please select - ",
    });
  },
  mounted() {
    // Prevents history traversal from Login page to other pages
    window.onpopstate = () => {
      if (this.$route.path == "/login") {
        this.$router.push("/login");
      }
    };
  },
};
</script>
<style>
.help-block {
  color: #f44335;
  font-size: 12px;
  margin-bottom: 20px !important;
}
</style>