export default {
    state: {
        currRequest: {},
    },
    getters: {
        requests: ( state ) => state.requests,
        currRequest: ( state ) => state.currRequest,
    },
    mutations: {
        SET_CURR_REQUEST ( state, request ) {
            state.currRequest = request;
        },
        SET_ALL_REQUESTS ( state, requests ) {
            state.requests = requests;
        },
    },
};
