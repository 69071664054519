var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('side-bar',{staticClass:"ehi",attrs:{"logo":"img/brand/brand.png","background-color":_vm.sidebarBackground,"short-title":"EHI","title":"EHI"}},[_c('template',{slot:"links"},[(_vm.isUserSectionVisible)?_c('sidebar-item',{attrs:{"link":{
          name: 'Users',
          icon: 'fa fa-user text-primary',
          path: '/users',
        },"short-title":"users","title":"Users"}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
          name: 'Quote Requests',
          icon: 'fa fa-calculator text-primary',
          path: '/quote-requests',
        },"short-title":"quote requests","title":"Quote Requests"}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Products',
          icon: 'fa fa-list text-primary',
          path: '/products',
        },"short-title":"products","title":"Products"}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Projects',
          icon: 'fa fa-handshake text-primary',
          path: '/projects',
        },"short-title":" projects","title":"Projects"}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Clients',
          icon: 'fa fa-users text-primary',
          path: '/clients',
        },"short-title":"clients","title":"Clients"}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Sales Contacts',
          icon: 'fa fa-phone text-primary',
          path: '/sales-contacts',
        },"short-title":"sales-contacts","title":"Sales Contacts"}})],1)],2),_c('div',{staticClass:"main-content",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar'),_c('div',{on:{"click":_vm.toggleSidebar}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }