import Vue from "vue";
import Router from "vue-router";
import DashboardLayout from "@/layout/DashboardLayout";
import AuthLayout from "@/layout/AuthLayout";
import { auth } from "./config/firebase";

Vue.use(Router);

const router = new Router({
  mode: "history",
  linkExactActiveClass: "active",
  routes: [
    {
      path: "/",
      redirect: "users",
      component: DashboardLayout,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: "/users",
          name: "users",
          component: () =>
            import(/* webpackChunkName: "users" */ "./views/User.vue"),
        },
        {
          path: "/quote-requests",
          name: "quote requests",
          component: () =>
            import(/* webpackChunkName: "quote-requests" */ "./views/Requests.vue"),
        },
        {
          path: "/products",
          name: "products",
          component: () =>
            import(/* webpackChunkName: "products" */ "./views/Products.vue"),
        },
        {
          path: "/projects",
          name: "projects",
          component: () =>
            import(/* webpackChunkName: "projects" */ "./views/Projects.vue"),
        },
        {
          path: "/clients",
          name: "clients",
          component: () =>
            import(/* webpackChunkName: "clients" */ "./views/Clients.vue"),
        },
        {
          path: "/sales-contacts",
          name: "sales contacts",
          component: () =>
            import(
              /* webpackChunkName: "sales-contacts" */ "./views/SalesContacts.vue"
            ),
        },
      ],
    },
    {
      path: "/",
      redirect: "login",
      component: AuthLayout,
      meta: {
        isAuthPage: true,
      },
      children: [
        {
          path: "/login",
          name: "login",
          component: () =>
            import(/* webpackChunkName: "login" */ "./views/Login.vue"),
        },
      ],
    },
  ],
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
  const isAuthPage = to.matched.some((x) => x.meta.isAuthPage);
  const currentUser = auth.currentUser;
  const allowedUser = "support@tawitech.ph";

  if (requiresAuth && !currentUser) {
    next("/login");
  } else if (currentUser) {
    if (isAuthPage && currentUser.email === allowedUser) {
      next("/");
    } else if (isAuthPage) {
      next("/quote-requests");
    } else {
      next();
    }
  } else {
    next();
  }
});
export default router;
