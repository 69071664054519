export default {
    state: {
        currClient: {},
    },
    getters: {
        clients: ( state ) => state.clients,
        currClient: ( state ) => state.currClient,
    },
    mutations: {
        SET_CURR_CLIENT ( state, client ) {
            state.currClient = client;
        },
        SET_ALL_CLIENTS ( state, clients ) {
            state.clients = clients;
        },
    },
};
