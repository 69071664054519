<template>
  <base-nav
    class="navbar-light"
    id="navbar-main"
    :show-toggle-button="false"
    expand
  >
    <form
      class="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto"
    ></form>
    <ul class="navbar-nav align-items-center d-none d-md-flex">
      <li class="nav-item dropdown">
        <base-dropdown class="nav-link pr-0">
          <div class="media align-items-center" slot="title">
            <span class="avatar avatar-sm rounded-circle">
              <img alt="Image placeholder" src="img/avatars/avatar.png" />
            </span>
            <div class="media-body ml-2 d-none d-lg-block">
              <span class="mb-0 text-sm font-weight-bold">{{
                $store.getters["user/getCurrentUser"].displayName
              }}</span>
            </div>
          </div>

          <template>
            <div class="dropdown-header noti-title">
              <h6 class="text-overflow m-0">Welcome! <span class="text-underline">{{ $store.getters["user/getCurrentUser"].displayName }}</span></h6>
            </div>
            <div class="dropdown-divider"></div>
            <a :href="true" @click.prevent="logout" class="dropdown-item">
              <i class="ni ni-user-run"></i>
              <span>Logout</span>
            </a>
          </template>
        </base-dropdown>
      </li>
    </ul>
  </base-nav>
</template>

<script>
  import { auth } from "../config/firebase";

  export default {
    data() {
      return {
        activeNotifications: false,
        showMenu: false,
        searchQuery: "",
      };
    },
    methods: {
      toggleSidebar() {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
      },
      hideSidebar() {
        this.$sidebar.displaySidebar(false);
      },
      toggleMenu() {
        this.showMenu = !this.showMenu;
      },
      logout() {
        auth.signOut().then(() => {
          this.$store.commit("user/SET_USER_DETAILS", {});
          localStorage.removeItem("userCredentials");
          this.$router.replace("/login");
        });
      },
    },
  };
</script>
