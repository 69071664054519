export default {
  state: {
    userDetails: {
      email: "",
      displayName: "",
      uid: "",
    },
    users: {},
    currUser: {},
  },
  getters: {
    getCurrentUser ( state ) {
      return state.userDetails;
    },
    users: ( state ) => state.users,
    currUser: ( state ) => state.currUser,
  },
  mutations: {
    SET_USER_DETAILS ( state, payload ) {
      const details = {
        email: payload.email,
        displayName: payload.displayName,
        uid: payload.uid,
      };

      state.userDetails = details;
    },
    SET_CURR_USER ( state, user ) {
      state.currUser = user;
    },
    SET_ALL_USERS ( state, users ) {
      state.users = users;
    },
  },
};
