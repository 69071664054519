import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyD0-l_cCAMzEJnvohh4FRaSfDaLNHPdb0w",
  authDomain: "easyhomes-ph.firebaseapp.com",
  databaseURL: "https://easyhomes-ph.firebaseio.com",
  projectId: "easyhomes-ph",
  storageBucket: "easyhomes-ph.appspot.com",
  messagingSenderId: "945906176572",
  appId: "1:945906176572:web:7a1e582571c1676e30a939",
  measurementId: "G-FED1KXFL06",
};

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const currentUser = auth.currentUser;
const db = firebase.firestore();
const storage = firebase.storage();
const currentTimeStamp = firebase.firestore.FieldValue.serverTimestamp();

export { db, auth, storage, currentUser, currentTimeStamp };


  