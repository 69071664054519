export default {
  state: {
    currProject: {},
  },
  getters: {
    projects: (state) => state.projects,
    currProject: (state) => state.currProject,
  },
  mutations: {
    SET_CURR_PROJECT(state, project) {
      state.currProject = project;
    },
    SET_ALL_PROJECTS(state, projects) {
      state.projects = projects;
    },
  },
};
