export default {
  state: {
    currProduct: {},
  },
  getters: {
    products: (state) => state.products,
    currProduct: (state) => state.currProduct,
  },
  mutations: {
    SET_CURR_PRODUCT(state, product) {
      state.currProduct = product;
    },
    SET_ALL_PRODUCTS(state, products) {
      state.products = products;
    },
  },
};
